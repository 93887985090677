<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    VclList
  },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getContracts() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('contracts')
        .then(response => {
          if (response.data.status == 'success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body == '' || this.table.body == null) {
            this.table.empty = true
          }
        })
    },
  },
  mounted() {
    this.getContracts()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Meus Contratos</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Contrato</th>
                    <th>Status</th>
                    <th>Data</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td>{{ td.id.split('-')[0] }}</td>
                    <th>{{ td.title }}</th>
                    <th>
                      <span v-if="td.status === 'pending'" class="badge badge-soft-danger font-size-12">PENDENTE</span>
                      <span v-else-if="td.status === 'signed'" class="badge badge-soft-success font-size-12">ASSINADO</span>
                    </th>
                    <th>{{ td.date }}</th>
                    <td class="text-right">
                      <router-link :to="'/contracts/' + td.id" class="btn btn-default btn-sm btn-rounded" target="_blank">
                        {{ t('Ver') }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>